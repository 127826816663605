import { isEmpty } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _set from 'lodash/set';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Divider, DropdownItemProps, Form, Header, Icon, Popup } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

import { useListAkkioModelsQuery, useSaveLeadscoreConfigMutation } from 'src/api/admin/leadscore-plus';
import { useGetUserProfileQuery } from 'src/api/auth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Note } from 'src/styles';
import {
  LeadscoreConfig,
  LeadscoreDefaultRates,
  RuleFilter,
  ScoringThresholdOutcomeOptions,
  ScoringThresholdScoreOptions,
  ScoringThresholdWhenOptions,
} from 'src/types';
import { formatLeadscoreConfig } from 'src/utils';
import RuleFilters from './AdminRuleFilters';
import { Layout } from './styles';

type ValidationErrors = {
  // General
  name?: string;
  enabled?: string;
  enrichmentCensusEnabled?: string;
  // enrichmentCimaEnabled?: string;
  // enrichmentPhoneLookupEnabled?: string;
  enrichmentOverrideEnabled?: string;
};

const getInitialFormdata = (config: LeadscoreConfig): LeadscoreConfig => {
  const next = _cloneDeep(config);
  return next;
};

type Props = {
  config: LeadscoreConfig;
  isValid: boolean;
  setIsValid: (isValid: boolean) => void;
};

const AdminEditLeadscoreConfigForm = ({ config, isValid, setIsValid }: Props) => {
  const { data: user } = useGetUserProfileQuery();
  const { data: models, isLoading: modelsLoading } = useListAkkioModelsQuery({ limit: 100, offset: 0 });
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [saved, setSaved] = useState(true);
  const [viewErrors, setViewErrors] = useState(false);
  const [formdata, setFormdata] = useState<LeadscoreConfig>(() => getInitialFormdata(config));
  const [errors, setErrors] = useState<ValidationErrors>({} as ValidationErrors);
  const { mutateAsync, isLoading: saveLoading } = useSaveLeadscoreConfigMutation();

  const accountLeadscoreRates = user?.active_account?.billing.leadscorePlus.rates;

  const selectedModel = useMemo(() => {
    return models?.data.find(m => m.id === formdata.scoring.akkioModelId);
  }, [formdata.scoring.akkioModelId, models?.data]);

  // At least in Chrome, it only prevents page close and reload
  useEffect(() => {
    const preventNavigation = (e: any) => {
      e.preventDefault();
      e.returnValue = '';
      return '';
    };

    if (!saved) {
      window.addEventListener('beforeunload', preventNavigation);
    }

    return () => {
      window.removeEventListener('beforeunload', preventNavigation);
    };
  }, [saved]);

  useEffect(() => {
    if (!config) return;
    setFormdata(getInitialFormdata(config));
  }, [config]);

  const validate = useCallback(
    (c: LeadscoreConfig) => {
      let errors = {} as ValidationErrors;

      // General
      if (!c.name.trim()) {
        errors.name = 'Name is required';
      }

      if (selectedModel) {
        if (selectedModel.config.requiresCensus && !c.enrichment.censusEnabled) {
          errors.enrichmentCensusEnabled = 'Census must be enabled for this model';
        }

        // if (selectedModel.config.requiresPhoneLookup && !c.enrichment.phoneLookupEnabled) {
        //   errors.enrichmentPhoneLookupEnabled = 'Phone Lookup must be enabled for this model';
        // }

        // if (selectedModel.config.requiresCimaCert && !c.enrichment.cimaEnabled) {
        //   errors.enrichmentCimaEnabled = 'CIMA Cert must be enabled for this model';
        // }
      }

      const isValid = isEmpty(errors);

      if (!viewErrors) {
        errors = {} as ValidationErrors;
      }

      setErrors(errors);
      setIsValid(isValid);

      return isValid;
    },
    [selectedModel, setIsValid, viewErrors]
  );

  const saveConfig = useCallback(
    async (c: LeadscoreConfig, force = false) => {
      if (saved && !force) {
        return;
      }

      setApiMessage(undefined);

      const config = formatLeadscoreConfig(c);

      try {
        await mutateAsync({ config });
        setSaved(true);
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [mutateAsync, saved]
  );

  const debouncedSave = useMemo(() => _debounce(saveConfig, 3000), [saveConfig]);

  useEffect(() => {
    debouncedSave(formdata);
    validate(formdata);
  }, [formdata, debouncedSave, validate]);

  const onChange = useCallback((_, { checked, name, value }) => {
    setFormdata(prev => {
      // Convert numeric values to numbers
      const v = typeof checked !== 'undefined' ? checked : value;
      const next = _cloneDeep(prev);
      _set(next, name, v);
      return next;
    });

    setSaved(false);
  }, []);

  const toggleViewErrors = () => setViewErrors(prev => !prev);

  const addFilter = useCallback(() => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      _set(next, 'rules.filters', [
        ...(next.rules.filters || []),
        { id: uuid(), value: '', field: '', operator: '' } as RuleFilter,
      ]);
      return next;
    });
    setSaved(false);
  }, []);

  const removeFilter = useCallback((id: string) => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      _set(
        next,
        'rules.filters',
        (next.rules.filters || []).filter(f => f.id !== id)
      );
      return next;
    });
    setSaved(false);
  }, []);

  const updateFilter = useCallback((id: string, filter: RuleFilter) => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      _set(
        next,
        'rules.filters',
        (next.rules.filters || []).map(f => (f.id === id ? filter : f))
      );
      return next;
    });
    setSaved(false);
  }, []);

  const isAdmin = user?.role === 'admin';

  const requiredColumnsOptions: DropdownItemProps[] = [
    ...((formdata.enrichment.censusEnabled &&
      user?.active_account?.brooks?.censusProperties?.map(p => ({
        key: p,
        value: p,
        text: p,
      }))) ||
      []),
    // ...((formdata.enrichment.cimaEnabled &&
    //   user?.active_account?.brooks?.cimaProperties?.map(p => ({
    //     key: p,
    //     value: p,
    //     text: p,
    //   }))) ||
    //   []),
    // ...((formdata.enrichment.phoneLookupEnabled &&
    //   user?.active_account?.brooks?.phoneLookupProperties?.map(p => ({
    //     key: p,
    //     value: p,
    //     text: p,
    //   }))) ||
    //   []),
  ];

  return (
    <Form style={{ position: 'relative' }} onSubmit={() => saveConfig(formdata, true)}>
      <ApiMessage data={apiMessage} />

      <div
        style={{ position: 'absolute', top: '0', right: '0', zIndex: 100, display: 'flex', justifyContent: 'flex-end' }}
      >
        {isValid ? (
          <Button size="mini" compact color="green" style={{ marginLeft: '0.5rem' }} type="button">
            <Icon name="check" />
            Valid
          </Button>
        ) : (
          <Popup
            trigger={
              <Button
                size="mini"
                compact
                color="red"
                style={{ marginLeft: '0.5rem' }}
                type="button"
                onClick={toggleViewErrors}
              >
                <Icon name="dont" />
                Invalid
                <Icon name={viewErrors ? 'eye' : 'eye slash'} style={{ marginLeft: '0.5rem', marginRight: 0 }} />
              </Button>
            }
          >
            {viewErrors ? 'Hide' : 'Show'} validation errors
          </Popup>
        )}

        <Button size="mini" compact color={saveLoading ? 'blue' : saved ? 'green' : 'red'} style={{ margin: 0 }}>
          {saveLoading ? <Icon name="spinner" loading /> : <Icon name={saved ? 'check' : 'dont'} />}
          {saveLoading ? 'Saving...' : saved ? 'Saved' : 'Unsaved'}
        </Button>
      </div>

      <Layout>
        <div style={{ gridArea: 'general' }}>
          <Header>General</Header>

          <Form.Input label="Name" name="name" value={formdata.name} onChange={onChange} error={errors.name} />

          <Form.Checkbox
            label="Enabled"
            name="enabled"
            toggle
            checked={formdata.enabled}
            onChange={onChange}
            error={errors.enabled}
          />

          <Divider />

          <Header>Enrichment</Header>

          <Form.Group style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Form.Checkbox
              toggle
              label="Enable Census"
              checked={formdata.enrichment.censusEnabled}
              name="enrichment.censusEnabled"
              onChange={onChange}
              error={!!errors.enrichmentCensusEnabled}
            />
            {isAdmin && (
              <Form.Input
                name="enrichment.censusRate"
                size="mini"
                placeholder={accountLeadscoreRates?.census || LeadscoreDefaultRates.census}
                value={formdata.enrichment.censusRate || ''}
                onChange={onChange}
              />
            )}
          </Form.Group>
          <Note>
            Using the provided lead data we will attempt to enrich the record with census data like race, ethnicity,
            median age, median income, and home value.
          </Note>

          {/* <Form.Group style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Form.Checkbox
              toggle
              label="Enable Phone Lookup"
              checked={formdata.enrichment.phoneLookupEnabled}
              name="enrichment.phoneLookupEnabled"
              onChange={onChange}
              error={!!errors.enrichmentPhoneLookupEnabled}
            />
            {isAdmin && (
              <Form.Input
                name="enrichment.phoneLookupRate"
                size="mini"
                placeholder={accountLeadscoreRates?.phoneLookup || LeadscoreDefaultRates.phoneLookup}
                value={formdata.enrichment.phoneLookupRate || ''}
                onChange={onChange}
              />
            )}
          </Form.Group>
          <Note>
            Using the provided phone number, we will attempt to enrich the record with some basic data like full name,
            physical address, and phone information such as type and carrier.
          </Note> */}

          {/* <div style={{ opacity: !formdata.enrichment.phoneLookupEnabled ? '0.25' : '1' }}>
            <Form.Group style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Form.Checkbox
                toggle
                label="Enable Demographics"
                checked={formdata.enrichment.demographicsEnabled}
                name="enrichment.demographicsEnabled"
                onChange={onChange}
                error={!!errors.enrichmentDemographicsEnabled}
              />
              {isAdmin && (
                <Form.Input
                  name="enrichment.demographicsRate"
                  size="mini"
                  placeholder={accountLeadscoreRates?.demographics || LeadscoreDefaultRates.demographics}
                  value={formdata.enrichment.demographicsRate || ''}
                  onChange={onChange}
                />
              )}
            </Form.Group>
            <Note>
              <strong>(Requires: Phone Lookup to be enabled)</strong> Using the provided phone number, we will attempt
              to further enrich the record with a variety of demographic and lifestyle attributes such as age,
              homeowner, length at residence, estimated income, gender, and race.
            </Note>
          </div> */}

          {/* <Form.Group style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Form.Checkbox
              toggle
              label="Enable CIMA Cert"
              checked={formdata.enrichment.cimaEnabled}
              name="enrichment.cimaEnabled"
              onChange={onChange}
              error={!!errors.enrichmentCimaEnabled}
            />
            {isAdmin && (
              <Form.Input
                name="enrichment.cimaRate"
                size="mini"
                placeholder={accountLeadscoreRates?.cima || LeadscoreDefaultRates.cima}
                value={formdata.enrichment.cimaRate || ''}
                onChange={onChange}
              />
            )}
          </Form.Group>
          <Note>
            Using the provided phone number, full name, and physical address, we will run a Complete Integrated
            Marketing Append to add a wide range of demographic data and validation scores.
          </Note> */}

          <Form.Group style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Form.Checkbox
              label="Override existing data"
              name="enrichment.overrideEnabled"
              toggle
              checked={formdata.enrichment.overrideEnabled}
              onChange={onChange}
              error={!!errors.enrichmentOverrideEnabled}
            />
          </Form.Group>
          <Note>
            Enrichment data will only be added into empty fields and will not overwrite any existing data found in the
            dataset unless the "Override existing data" toggle is enabled.
          </Note>
          <Note>
            Keep in mind that this also applies to data that was previously enriched, even if you disable any of the
            enrichments above.
          </Note>
        </div>

        <div style={{ gridArea: 'rules' }}>
          <Header>Rules</Header>

          {/* <Form.Select
            loading={profileLoading}
            multiple
            options={requiredColumnsOptions}
            label="Required Columns"
            value={formdata.rules.requiredFields || []}
            name="rules.requiredFields"
            onChange={onChange}
          /> */}

          <Header as="h4">Filters</Header>
          <Note>
            To filter data in a specific column you must first make it required. Ingested data will be rejected if ANY
            filter does not pass.
          </Note>

          <RuleFilters
            columns={requiredColumnsOptions}
            filters={formdata.rules.filters || []}
            addFilter={addFilter}
            removeFilter={removeFilter}
            updateFilter={updateFilter}
          />
        </div>

        <div style={{ gridArea: 'scoring' }}>
          <Header>Scoring</Header>

          <Form.Select
            placeholder="Select a model"
            options={
              models?.data?.map(m => ({
                key: m.id,
                value: m.id,
                text: m.name,
              })) || []
            }
            clearable
            label="LeadScore+ Model"
            value={formdata.scoring.akkioModelId || ''}
            name="scoring.akkioModelId"
            onChange={onChange}
            loading={modelsLoading}
            error={
              errors.enrichmentCensusEnabled
              // || errors.enrichmentCimaEnabled
              // || errors.enrichmentPhoneLookupEnabled
            }
          />
          {isAdmin && (
            <Form.Input
              label="Rate"
              name="scoring.rate"
              size="mini"
              placeholder={accountLeadscoreRates?.prediction || LeadscoreDefaultRates.prediction}
              value={formdata.scoring.rate || ''}
              onChange={onChange}
            />
          )}

          <Header as="h4">Threshold</Header>
          <Note>
            Records that do not pass the outcome will be rejected by the API, however, the data and prediction will
            still be written to the dataset.
          </Note>

          <Form.Select
            label="Outcome"
            placeholder="Select outcome"
            clearable
            fluid
            options={ScoringThresholdOutcomeOptions.map(t => ({ ...t, key: t.value }))}
            value={formdata.scoring.threshold.outcome || ''}
            name="scoring.threshold.outcome"
            onChange={onChange}
          />

          {formdata.scoring.threshold.outcome === 'score' && (
            <Form.Group widths="equal">
              <Form.Select
                placeholder="Select score"
                clearable
                fluid
                options={ScoringThresholdScoreOptions.map(t => ({ ...t, key: t.value }))}
                value={formdata.scoring.threshold.score || ''}
                name="scoring.threshold.score"
                onChange={onChange}
              />
              <Form.Select
                clearable
                placeholder="Select when"
                fluid
                options={ScoringThresholdWhenOptions.map(t => ({ ...t, key: t.value }))}
                value={formdata.scoring.threshold.when || ''}
                name="scoring.threshold.when"
                onChange={onChange}
              />
              {['between', 'gte', 'gt'].includes(formdata.scoring.threshold.when) && (
                <Form.Input
                  fluid
                  placeholder="from"
                  value={formdata.scoring.threshold.from || ''}
                  name="scoring.threshold.from"
                  onChange={onChange}
                />
              )}
              {['between', 'eq', 'lte', 'lt'].includes(formdata.scoring.threshold.when) && (
                <Form.Input
                  fluid
                  placeholder="to"
                  value={formdata.scoring.threshold.to || ''}
                  name="scoring.threshold.to"
                  onChange={onChange}
                />
              )}
            </Form.Group>
          )}
        </div>
      </Layout>
    </Form>
  );
};

export default AdminEditLeadscoreConfigForm;
