import { useRef, useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

import { DatasetUploadResponse } from 'src/api/datasets';
import ApiMessage from 'src/components/ApiMessage';
import { Dataset } from 'src/types';
import UploadToDatasetV2Form from './UploadToDatasetV2Form';

type Props = {
  dataset: Dataset;
};

const UploaToDatasetV2Modal = ({ dataset }: Props) => {
  const modalRef = useRef(null);
  const [response, setResponse] = useState<DatasetUploadResponse>();

  const onCancel = () => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
    }
  };

  const onClose = () => {
    setResponse(undefined);
  };

  const onSuccess = (res: DatasetUploadResponse) => {
    setResponse(res);
    setTimeout(onCancel, 3000);
  };

  return (
    <Modal
      ref={modalRef}
      trigger={
        <Button type="button" color="blue" icon title="Upload data">
          <Icon name="upload" /> Upload
        </Button>
      }
      onClose={onClose}
      size="large"
    >
      <Modal.Header>Upload to Dataset: {dataset.name}</Modal.Header>
      <Modal.Content>
        {typeof response !== 'undefined' ? (
          <>
            <ApiMessage
              data={{
                success: true,
                status: 200,
                message:
                  'A background job has been successfully started to ingest your data to the dataset. Please check the job history for more details.',
              }}
            />
            <Button type="button" onClick={onCancel} fluid color="blue">
              Done
            </Button>
          </>
        ) : (
          <UploadToDatasetV2Form datasetId={dataset.id} onCancel={onCancel} onSuccess={onSuccess} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default UploaToDatasetV2Modal;
