import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { Dispatch, Fragment, SetStateAction, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Button,
  Form,
  Icon,
  Input,
  InputOnChangeData,
  TextArea,
} from 'semantic-ui-react';

import PublicFileUploader from 'src/components/ImageUploader';
import { AccordionTitleText } from 'src/styles';
import {
  newQualifaiConversationGenerativeAgent,
  newQualifaiConversationQuestion,
  QualifaiConversation,
  QualifaiConversationQuestion,
} from 'src/types';
import { getAudioDuration } from 'src/utils';
import ButtonTranscribeAudio from './ButtonTranscribeAudio';
import { ValidationErrors } from './EditQualifaiConversationForm';
import { ReorderButton } from './style';

type Props = {
  errors: ValidationErrors;
  formdata: QualifaiConversation;
  onChange: (e: SyntheticEvent, data: any) => void;
  setFormdata: Dispatch<SetStateAction<QualifaiConversation>>;
  setSaved: Dispatch<SetStateAction<boolean>>;
  formdataKey: 'fronter' | 'closer';
  questionsKey: 'questions' | 'rebuttals' | 'fallbacks' | 'hangupEndings' | 'transferEndings';
  questionTitle: string;
};

const AccordionQuestions = ({
  errors,
  formdata,
  onChange,
  setFormdata,
  setSaved,
  formdataKey,
  questionsKey,
  questionTitle,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isLoadingAudioFile, setIsLoadingAudioFile] = useState(false);

  const reorderUp = useCallback(
    (i: number) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);

        const nextAgent = _get(next, formdataKey) || newQualifaiConversationGenerativeAgent();
        const nextQuestions = _get(nextAgent, questionsKey) || [];

        if (nextQuestions.length <= 1) return next;

        const temp = nextQuestions[i];
        nextQuestions[i] = nextQuestions[i - 1];
        nextQuestions[i - 1] = temp;

        next[formdataKey] = { ...nextAgent, [questionsKey]: nextQuestions };

        return next;
      });

      if (activeIndex === i) {
        setActiveIndex(i - 1);
      }
    },
    [activeIndex, formdataKey, questionsKey, setFormdata]
  );

  const reorderDown = useCallback(
    (i: number) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);

        const nextAgent = _get(next, formdataKey) || newQualifaiConversationGenerativeAgent();
        const nextQuestions = _get(nextAgent, questionsKey) || [];

        if (nextQuestions.length <= 1) return next;

        const temp = nextQuestions[i];
        nextQuestions[i] = nextQuestions[i + 1];
        nextQuestions[i + 1] = temp;

        next[formdataKey] = { ...nextAgent, [questionsKey]: nextQuestions };

        return next;
      });

      if (activeIndex === i) {
        setActiveIndex(i + 1);
      }
    },
    [activeIndex, formdataKey, questionsKey, setFormdata]
  );

  const addQuestion = useCallback(() => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);

      const nextAgent = _get(next, formdataKey) || newQualifaiConversationGenerativeAgent();
      const nextQuestions = _get(nextAgent, questionsKey) || [];

      const count = nextQuestions.length + 1;
      const name = `${questionTitle || 'Question'} ${count}`;

      nextQuestions.push(newQualifaiConversationQuestion(name));

      setActiveIndex(nextQuestions.length - 1);

      next[formdataKey] = { ...nextAgent, [questionsKey]: nextQuestions };

      return next;
    });
    setSaved(false);
  }, [formdataKey, questionTitle, questionsKey, setFormdata, setSaved]);

  const removeQuestion = useCallback(
    (id: string) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);

        const nextAgent = _get(next, formdataKey) || newQualifaiConversationGenerativeAgent();
        const nextQuestions = (_get(nextAgent, questionsKey) || []).filter(q => q.id !== id);

        next[formdataKey] = { ...nextAgent, [questionsKey]: nextQuestions };

        return next;
      });
      setSaved(false);
      setActiveIndex(0);
    },
    [formdataKey, questionsKey, setFormdata, setSaved]
  );

  const updateAudioDuration = useCallback(
    (event: any, { checked, name, value }: InputOnChangeData) => {
      if (!!value) {
        setIsLoadingAudioFile(true);
        getAudioDuration(value)
          .then(duration => {
            const durationInMs = Math.trunc(duration * 1000);
            const durationNodeName = name.replace('audioUrl', 'audioDuration');
            onChange(event, { checked, name: durationNodeName, value: durationInMs });
            setIsLoadingAudioFile(false);
          })
          .catch(_ => setIsLoadingAudioFile(false));
      }
    },
    [onChange]
  );

  const resetToAudioFileDuration = useCallback(
    (name: string) => {
      const value = _get(formdata, name);
      updateAudioDuration('', { checked: undefined, name, value });
    },
    [formdata, updateAudioDuration]
  );

  const setAudioDurationAndFileUrl = useCallback(
    (event: SyntheticEvent<HTMLInputElement>, { checked, name, value }: InputOnChangeData) => {
      updateAudioDuration(event, { checked, name, value });
      onChange(event, { checked, name, value });
    },
    [onChange, updateAudioDuration]
  );

  const onTranscribeAudio = useCallback(
    (index: number) => (text: string) => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);

        const nextAgent = _get(next, formdataKey) || newQualifaiConversationGenerativeAgent();
        const nextQuestions = _get(nextAgent, questionsKey) || [];

        if (nextQuestions.length === 0) return next;

        nextQuestions[index].audioTranscript = text;

        next[formdataKey] = { ...nextAgent, [questionsKey]: nextQuestions };

        return next;
      });
      setSaved(false);
    },
    [formdataKey, questionsKey, setFormdata, setSaved]
  );

  const questions: QualifaiConversationQuestion[] = useMemo(() => {
    return _get(formdata, `${formdataKey}.${questionsKey}`) || [];
  }, [formdata, formdataKey, questionsKey]);

  return (
    <>
      {questions.length > 0 && (
        <Accordion fluid styled style={{ marginBottom: '1rem' }}>
          {questions.map((q, i) => {
            return (
              <div key={q.id} style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 0.5rem',
                  }}
                >
                  <ReorderButton disabled={i === 0} type="button" onClick={reorderUp(i)}>
                    <Icon name="chevron up" />
                  </ReorderButton>

                  <ReorderButton disabled={i === questions.length - 1} type="button" onClick={reorderDown(i)}>
                    <Icon name="chevron down" />
                  </ReorderButton>
                </div>

                <AccordionTitle
                  active={activeIndex === i}
                  onClick={() => setActiveIndex(i === activeIndex ? -1 : i)}
                  style={i === 0 ? { borderTop: 0 } : undefined}
                >
                  <Icon name="dropdown" />
                  <AccordionTitleText error={!!(errors.nodes && errors.nodes[q.id])}>
                    {q.name} {!!(errors.nodes && errors.nodes[q.id]) && <Icon name="exclamation triangle" />}
                  </AccordionTitleText>
                </AccordionTitle>

                <AccordionContent active={activeIndex === i}>
                  <Form.Group>
                    <Form.Input
                      width={12}
                      label="Name"
                      name={`${formdataKey}.${questionsKey}.${i}.name`}
                      onChange={onChange}
                      value={q.name || ''}
                    />

                    <Form.Field width={4}>
                      <label>Allow Interruptions</label>
                      <Form.Checkbox
                        toggle
                        name={`${formdataKey}.${questionsKey}.${i}.allowInterruptions`}
                        checked={q.allowInterruptions}
                        onChange={onChange}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Field>
                    <Button
                      type="button"
                      size="mini"
                      compact
                      color={
                        q.ttsAudioStatus === 'generating' || q.ttsJobId !== ''
                          ? 'blue'
                          : q.ttsAudioStatus === 'needs_update'
                          ? 'orange'
                          : 'green'
                      }
                      style={{ position: 'relative', top: -4, float: 'right', margin: 0 }}
                      // disabled={q.ttsAudioStatus !== 'ready'}
                    >
                      <Icon
                        name={
                          q.ttsAudioStatus === 'generating' || q.ttsJobId !== ''
                            ? 'spinner'
                            : q.ttsAudioStatus === 'needs_update'
                            ? 'microphone slash'
                            : 'microphone'
                        }
                        loading={q.ttsAudioStatus === 'generating' || q.ttsJobId !== ''}
                      />
                      {q.ttsAudioStatus === 'generating' || q.ttsJobId !== ''
                        ? 'TTS Generating...'
                        : q.ttsAudioStatus === 'needs_update'
                        ? 'TTS Needs Update'
                        : 'TTS Ready'}
                    </Button>

                    <label>Audio Transcript</label>
                    <TextArea
                      name={`${formdataKey}.${questionsKey}.${i}.audioTranscript`}
                      onChange={(e, d) => {
                        onChange(e, d);
                        onChange(e, {
                          name: `${formdataKey}.${questionsKey}.${i}.ttsAudioStatus`,
                          value: 'needs_update',
                        });
                        onChange(e, { name: 'ttsVoiceStatus', value: 'needs_update' });
                      }}
                      value={q.audioTranscript || ''}
                    />
                  </Form.Field>

                  <Form.Group>
                    <Form.Field width={12}>
                      <ButtonTranscribeAudio audioUrl={questions[i].audioUrl || ''} onSuccess={onTranscribeAudio(i)} />

                      <label>Audio URL</label>
                      <Input
                        name={`${formdataKey}.${questionsKey}.${i}.audioUrl`}
                        onChange={setAudioDurationAndFileUrl}
                        value={q.audioUrl}
                        style={{ marginBottom: '1rem' }}
                      />
                      <PublicFileUploader
                        fileType="audio"
                        name={`${formdataKey}.${questionsKey}.${i}.audioUrl`}
                        onChange={onChange}
                        value={q.audioUrl}
                      />
                    </Form.Field>

                    <Form.Field width={4}>
                      <Button
                        type="button"
                        size="mini"
                        compact
                        color="blue"
                        style={{ position: 'relative', top: -4, float: 'right', margin: 0 }}
                        icon
                        disabled={!q.audioUrl}
                        onClick={() => resetToAudioFileDuration(`${formdataKey}.${questionsKey}.${i}.audioUrl`)}
                      >
                        <Icon name="refresh" />
                      </Button>

                      <label>Audio Duration (ms)</label>
                      <Input
                        name={`${formdataKey}.${questionsKey}.${i}.audioDuration`}
                        onChange={onChange}
                        value={q.audioDuration || ''}
                        style={{ marginBottom: '0.5rem' }}
                        loading={isLoadingAudioFile}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Button type="button" color="red" basic onClick={removeQuestion(q.id)}>
                    <Icon name="trash" />
                    Delete {questionTitle || 'Question'}
                  </Button>
                </AccordionContent>
              </div>
            );
          })}
        </Accordion>
      )}

      <Button type="button" size="mini" compact color="blue" onClick={addQuestion}>
        <Icon name="plus" />
        Add {questionTitle || 'Question'}
      </Button>
    </>
  );
};

export default AccordionQuestions;
