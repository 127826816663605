import { formatDistanceToNow } from 'date-fns';
import { noop } from 'lodash';
import { createElement, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Popup, Progress, Table } from 'semantic-ui-react';

import { useGetAdminJobsQuery } from 'src/api/admin/jobs';
import { useListDatasetsQuery } from 'src/api/datasets';
import { useGetJobsQuery } from 'src/api/jobs';
import DeleteJob from 'src/components/JobHistory/DeleteJob';
import StopJob from 'src/components/JobHistory/StopJob';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import { DatasetUploadJob, JobStatus, JobType, ScheduleJob } from 'src/types';
import DuplicateJob from './DuplicateJob';
import JobStatusLabel from './JobStatusLabel';

type RenderJobFileNameProps = {
  job: DatasetUploadJob | ScheduleJob;
};

const RenderJobFileName = ({ job }: RenderJobFileNameProps) => {
  const rawGSPath = job.payload.raw_gs_path;
  const filename = rawGSPath?.split('/').at(-1)?.split('-').slice(3).join('-');
  return <Table.Cell>{filename}</Table.Cell>;
};

type Props = RenderProps & {
  adminJobs?: boolean;
  details?: (j: any) => JSX.Element;
  jobType?: JobType;
};

const JobsListBody = ({ adminJobs, limit, currentPage, setPageCount, details, jobType }: Props) => {
  const { id } = useParams<{ id: string }>();

  const queryFn = adminJobs ? useGetAdminJobsQuery : useGetJobsQuery;
  const { data: jobs } = queryFn({
    limit,
    offset: (currentPage - 1) * limit,
    type: jobType,
    objectid: id,
  });

  const { data: datasetsResp } = useListDatasetsQuery({
    limit: 100,
    offset: 0,
  });

  const datasets = useMemo(() => {
    if (!datasetsResp?.data) return {};
    return datasetsResp.data.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.name }), {} as { [key: number]: string });
  }, [datasetsResp]);

  useEffect(() => {
    if (!jobs?.total) return;
    setPageCount(Math.ceil(jobs.total / limit));
  }, [setPageCount, jobs?.total, limit]);

  if (typeof jobs === 'undefined') return null;

  return (
    <>
      {jobs.data.map(job => (
        <Table.Row key={job.id}>
          <Table.Cell collapsing>{job.id}</Table.Cell>
          {(job.type === JobType.Predict || job.type === JobType.DatasetUpload) && (
            <RenderJobFileName job={job.type === JobType.Predict ? (job as ScheduleJob) : (job as DatasetUploadJob)} />
          )}
          {job.type === JobType.DatasetUpload && job.objectid && <Table.Cell>{datasets[job.objectid]}</Table.Cell>}
          <Table.Cell>
            {job.queued && formatDistanceToNow(new Date(job.queued), { addSuffix: true, includeSeconds: true })}
          </Table.Cell>
          <Table.Cell>
            {job.status === JobStatus.Error && job.payload.error ? (
              <Popup
                trigger={
                  <span>
                    <JobStatusLabel status={job.status} />
                  </span>
                }
              >
                {job.payload.error}
              </Popup>
            ) : job.status === JobStatus.Processing || job.status === JobStatus['Processing Externally'] ? (
              <Progress
                indicating
                percent={job.stage === 1 ? job.progress1 * 100 : job.stage === 2 ? job.progress2 * 100 : 0}
                content={job.payload.message || `Stage ${job.stage}`}
              />
            ) : (
              <JobStatusLabel status={job.status} />
            )}
          </Table.Cell>
          <Table.Cell>
            {job.started && formatDistanceToNow(new Date(job.started), { addSuffix: true, includeSeconds: true })}
          </Table.Cell>
          <Table.Cell>
            {job.heartbeat && formatDistanceToNow(new Date(job.heartbeat), { addSuffix: true, includeSeconds: true })}
          </Table.Cell>
          <Table.Cell collapsing>
            <Row style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              {/* @ts-ignore */}
              {typeof details !== 'undefined' && createElement(details, job)}
              {[JobType.DatasetsV2Upload, JobType.Predict].includes(job.type) && <DuplicateJob id={job.id} />}
              {(job.status === JobStatus.Processing || job.status === JobStatus['Processing Externally']) && (
                <StopJob id={job.id} onClose={noop} />
              )}
              {job.status !== JobStatus.Completed && job.status !== JobStatus.Cancelled && (
                <DeleteJob id={job.id} onClose={noop} />
              )}
            </Row>
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default JobsListBody;
