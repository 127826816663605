import { useRef, useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

import { BlacklistBulkAddResponse } from 'src/api/blacklists';
import ApiMessage from 'src/components/ApiMessage';
import { Blacklist } from 'src/types';
import BlacklistAddForm from './BlacklistAddForm';

type Props = {
  blacklist: Blacklist;
};

const BlacklistAddModal = ({ blacklist }: Props) => {
  const modalRef = useRef(null);
  const [response, setResponse] = useState<BlacklistBulkAddResponse>();

  const onCancel = () => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
    }
  };

  const onClose = () => {
    setResponse(undefined);
  };

  const onSuccess = (res: BlacklistBulkAddResponse) => setResponse(res);

  let message = '';
  if (response) {
    message = 'Your data was successfully added to the list.';
    if (response.phones_added) {
      message += ` ${response.phones_added} Phones Added.`;
    }
    if (response.emails_added) {
      message += ` ${response.emails_added} Emails Added.`;
    }
  }

  return (
    <Modal
      ref={modalRef}
      trigger={
        <Button type="button" color="blue" icon title="Add Data">
          <Icon name="plus" /> Add Data
        </Button>
      }
      onClose={onClose}
    >
      <Modal.Header>Add to Blacklist: {blacklist.name}</Modal.Header>
      <Modal.Content>
        {typeof response !== 'undefined' ? (
          <>
            <ApiMessage data={{ success: true, status: 200, message }} />
            <Button type="button" onClick={onCancel} fluid color="blue">
              Done
            </Button>
          </>
        ) : (
          <BlacklistAddForm blacklist={blacklist} onCancel={onCancel} onSuccess={onSuccess} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default BlacklistAddModal;
