import { useCallback, useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useGenerateQualifaiConversationTTSAudiosMutation } from 'src/api/qualifai-conversations';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

type Props = {
  conversationId: string;
  disabled: boolean;
};

const GenerateTTSAudiosModal = ({ conversationId, disabled }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useGenerateQualifaiConversationTTSAudiosMutation();

  const onClose = () => {
    setApiMessage(undefined);
    setOpen(false);
  };

  const onConfirm = useCallback(async () => {
    try {
      await mutateAsync(conversationId);
      onClose();
    } catch (error) {
      apiErrorHandler(error, setApiMessage);
    }
  }, [conversationId, mutateAsync]);

  return (
    <Modal
      size="mini"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button size="mini" compact color="orange" style={{ marginLeft: '0.5rem' }} type="button" disabled={disabled}>
          <Icon name="microphone slash" />
          TTS Needs Update
        </Button>
      }
    >
      <Modal.Header>Generate Text-to-Speech Audios</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <p>
          Audio files will be generated for all questions in this conversation. All Audio URLs will be replaced with the
          generated audio as each one becomes available.
        </p>

        <p style={{ marginTop: 0 }}>
          <strong>Are you sure?</strong> This action cannot be stopped.
        </p>

        <Row>
          <Button fluid type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button type="button" color="blue" fluid loading={isLoading} onClick={onConfirm}>
            Confirm
          </Button>
        </Row>
      </Modal.Content>
    </Modal>
  );
};

export default GenerateTTSAudiosModal;
