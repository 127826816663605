import { capitalize, isEmpty, set } from 'lodash';
import { SyntheticEvent, useState } from 'react';
import { Button, CheckboxProps, Divider, Form, Grid, Header, InputOnChangeData } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { DsCensusPropertyOptions } from 'src/data';
import { Note, Row } from 'src/styles';
import { Account, AccountBrooks, AccountMetadata, AccountPermission, AccountReporting } from 'src/types';
import AdminAccountSettingsMetadata from './AdminAccountSettingsMetadata';

type ValidationErrors = {
  name?: string;
  ownerEmail?: string;
  metadata?: string;
};

type Props = {
  account: Account;
};

const AdminAccountSettings = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [name, setName] = useState(account.name || '');
  const [ownerEmail, setOwnerEmail] = useState(account.ownerEmail || '');
  const [permissions, setPermissions] = useState<AccountPermission[]>(account.permissions || []);
  const [reporting, setReporting] = useState<AccountReporting>(
    account.reporting ||
      ({
        carriers: { enabled: true },
        datascore: {
          enabled: true,
          dials: true,
          connects: true,
          connects_pct: true,
          inbounds: true,
          transfers: true,
          transfers_pct: true,
          vmdrops: false,
        },
      } as AccountReporting)
  );
  const [metadata, setMetadata] = useState<AccountMetadata>(account.metadata);
  const [brooks, setBrooks] = useState<AccountBrooks>(
    account.brooks || {
      censusProperties: [],
      // cimaProperties: [],
      // phoneLookupProperties: [],
    }
  );
  const { isLoading, mutateAsync } = useUpdateAccountMutation();

  const onChangeName = (_e: React.FormEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
    if (typeof value === 'undefined' || typeof value === 'number') return;
    setName(value);
  };

  const onChangeOwnerEmail = (_e: SyntheticEvent<HTMLElement, Event>, { value }: InputOnChangeData) => {
    if (typeof value === 'undefined') return;
    setOwnerEmail(value as string);
  };

  const onChangePermission = (_e: React.FormEvent<HTMLInputElement>, { checked, value }: CheckboxProps) => {
    if (typeof value === 'undefined' || typeof value === 'number') return;

    setPermissions(prev => {
      let perms = [...prev];

      if (checked) {
        perms.push(value as AccountPermission);
      } else {
        perms = perms.filter(v => v !== value);
      }

      return perms;
    });
  };

  const onChangeReporting = (_e: React.FormEvent<HTMLInputElement>, { checked, name }: CheckboxProps) => {
    setReporting(prev => {
      const next = { ...prev };
      set(next, String(name), checked);
      return next;
    });
  };

  const onSubmit = async () => {
    setApiMessage(undefined);

    const validationErrors = {} as ValidationErrors;

    if (name === '') {
      validationErrors.name = 'Name is required';
    }

    // if (ownerEmail === "") {
    //   validationErrors.ownerEmail = 'Owner email is required';
    // }

    setErrors(validationErrors);

    if (!isEmpty(validationErrors)) {
      return;
    }

    try {
      await mutateAsync({ id: account.id, name, ownerEmail, permissions, reporting, metadata, brooks });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Form.Input label="Name" name="name" value={name} onChange={onChangeName} error={errors.name} />

            <Form.Input
              label="Owner Email"
              name="ownerEmail"
              value={ownerEmail}
              onChange={onChangeOwnerEmail}
              error={errors.ownerEmail}
              multiple={false}
            />

            <Note>
              This setting is especially important for the setup of the Stripe billing account. This user will also
              receive email notifications when new voicemails are received by one of the Voice Configurations.
            </Note>
          </Grid.Column>

          <Grid.Column>
            <Form.Field>
              <label>Permissions</label>

              <Row style={{ flexWrap: 'wrap', columnGap: '1rem', rowGap: '0.5rem' }}>
                {Object.values(AccountPermission).map(p => (
                  <Form.Checkbox
                    key={p}
                    label={capitalize(p)}
                    value={p}
                    checked={permissions.includes(p)}
                    onChange={onChangePermission}
                  />
                ))}
              </Row>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>

        <Divider />

        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h4">Reporting</Header>

            <Row>
              <Form.Checkbox
                toggle
                label="Show Datascore Stats"
                name="datascore.enabled"
                checked={reporting.datascore.enabled}
                onChange={onChangeReporting}
              />

              <Row style={{ marginLeft: '1rem', gap: '1rem', opacity: reporting.datascore.enabled ? 1 : 0.25 }}>
                <Form.Checkbox
                  label="Dials"
                  name="datascore.dials"
                  checked={reporting.datascore.dials}
                  onChange={onChangeReporting}
                />
                <Form.Checkbox
                  label="Connects"
                  name="datascore.connects"
                  checked={reporting.datascore.connects}
                  onChange={onChangeReporting}
                />
                <Form.Checkbox
                  label="Connects %"
                  name="datascore.connects_pct"
                  checked={reporting.datascore.connects_pct}
                  onChange={onChangeReporting}
                />

                <Form.Checkbox
                  label="Inbounds"
                  name="datascore.inbounds"
                  checked={reporting.datascore.inbounds}
                  onChange={onChangeReporting}
                />
                <Form.Checkbox
                  label="Transfers"
                  name="datascore.transfers"
                  checked={reporting.datascore.transfers}
                  onChange={onChangeReporting}
                />
                <Form.Checkbox
                  label="Transfers %"
                  name="datascore.transfers_pct"
                  checked={reporting.datascore.transfers_pct}
                  onChange={onChangeReporting}
                />
                <Form.Checkbox
                  label="VM Drops"
                  name="datascore.vmdrops"
                  checked={reporting.datascore.vmdrops}
                  onChange={onChangeReporting}
                />
              </Row>
            </Row>

            <Form.Checkbox
              toggle
              label="Show Carrier Stats"
              name="carriers.enabled"
              checked={reporting.carriers.enabled}
              onChange={onChangeReporting}
            />
          </Grid.Column>
        </Grid.Row>

        <Divider />

        <Grid.Row>
          <Grid.Column>
            <AdminAccountSettingsMetadata
              metadata={metadata}
              onChange={m => setMetadata(m)}
              onError={e => {
                if (e !== '') {
                  setErrors({ ...errors, metadata: e });
                  return;
                }
                const obj = { ...errors };
                delete obj.metadata;
                setErrors(obj);
              }}
            />
          </Grid.Column>
        </Grid.Row>

        <Divider />

        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h4">LeadScore+</Header>

            <Form.Select
              clearable
              multiple
              search
              label="Census Properties"
              options={DsCensusPropertyOptions}
              value={brooks.censusProperties || []}
              onChange={(_, { value }) =>
                setBrooks(prev => ({
                  ...prev,
                  censusProperties: value as string[],
                }))
              }
            />

            {/* <Form.Select
              clearable
              multiple
              search
              label="Brooks Phone Lookup Properties"
              options={BrooksPhoneLookupPropertyOptions}
              value={brooks.phoneLookupProperties || []}
              onChange={(_, { value }) =>
                setBrooks(prev => ({
                  ...prev,
                  phoneLookupProperties: value as string[],
                }))
              }
            /> */}

            {/* <Form.Select
              clearable
              multiple
              search
              label="Brooks CIMA Cert Properties"
              options={BrooksCimaCertPropertyOptions}
              value={brooks.cimaProperties || []}
              onChange={(_, { value }) =>
                setBrooks(prev => ({
                  ...prev,
                  cimaProperties: value as string[],
                }))
              }
            /> */}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Button color="blue" loading={isLoading} disabled={!!errors.metadata}>
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default AdminAccountSettings;
