import { cloneDeep, set } from 'lodash';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  CheckboxProps,
  Divider,
  DropdownProps,
  Form,
  Grid,
  Header,
  Icon,
  InputOnChangeData,
  Label,
} from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { BillingPaymentAmounts } from 'src/config';
import theme from 'src/styles/theme';
import { Account, AccountBilling, LeadscoreDefaultRates } from 'src/types';
import { formatBilling } from 'src/utils';
import AdminAccountBalanceAdjustment from './AdminAccountBalanceAdjustment';

type Props = {
  account: Account;
};

const AdminAccountBillingLeadscorePlus = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<AccountBilling>(() => cloneDeep(account.billing));
  const { isLoading, mutateAsync } = useUpdateAccountMutation();

  const onChange = useCallback((_, { name, checked, value }: CheckboxProps | DropdownProps | InputOnChangeData) => {
    setFormdata(prev => {
      const v = typeof checked !== 'undefined' ? checked : String(value);

      const next = cloneDeep(prev);
      set(next, name, v);
      return next;
    });
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    const billing = formatBilling(formdata);

    try {
      await mutateAsync({ id: account.id, billing });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [account.id, formdata, mutateAsync]);

  const currentBalance = Number(account.currentBalanceLeadscorePlus || 0);

  return (
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <Form onSubmit={onSubmit}>
            <Header as="h4">Settings</Header>

            <ApiMessage data={apiMessage} />

            <Form.Checkbox
              toggle
              name="leadscorePlus.enabled"
              label="LeadScore+ Billing Enabled?"
              checked={formdata.leadscorePlus.enabled}
              onChange={onChange}
            />

            <div style={{ opacity: formdata.leadscorePlus.enabled ? undefined : 0.5 }}>
              <Form.Field>
                <label>
                  Enrichment Rates <span style={{ color: theme.gray }}>(in dollars)</span>
                </label>
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Input
                  label="Census"
                  name="leadscorePlus.rates.census"
                  placeholder={LeadscoreDefaultRates.census}
                  value={String(formdata.leadscorePlus.rates.census || '')}
                  onChange={onChange}
                />
                {/* <Form.Input
                  label="CIMA Cert"
                  placeholder={LeadscoreDefaultRates.cima}
                  name="leadscorePlus.rates.cima"
                  value={String(formdata.leadscorePlus.rates.cima || '')}
                  onChange={onChange}
                /> */}
              </Form.Group>
              {/* <Form.Group widths="equal">
                <Form.Input
                  label="Phone Lookup"
                  placeholder={LeadscoreDefaultRates.phoneLookup}
                  name="leadscorePlus.rates.phoneLookup"
                  value={String(formdata.leadscorePlus.rates.phoneLookup || '')}
                  onChange={onChange}
                />
              </Form.Group> */}
              <Form.Group widths="equal">
                <Form.Input
                  label="Prediction"
                  placeholder={LeadscoreDefaultRates.prediction}
                  name="leadscorePlus.rates.prediction"
                  value={String(formdata.leadscorePlus.rates.prediction || '')}
                  onChange={onChange}
                />
              </Form.Group>

              <Divider />

              <Form.Group>
                <Form.Select
                  width={10}
                  fluid
                  label="Deposit Amount"
                  name="leadscorePlus.deposit.amount"
                  value={String(formdata.leadscorePlus.deposit.amount)}
                  onChange={onChange}
                  options={[0, ...BillingPaymentAmounts].map(a => ({
                    key: a,
                    value: String(a),
                    text: '$' + a.toLocaleString(),
                  }))}
                />
                <Form.Select
                  width={6}
                  fluid
                  label="Paid"
                  name="leadscorePlus.deposit.paid"
                  value={formdata.leadscorePlus.deposit.paid ? 'yes' : 'no'}
                  onChange={onChange}
                  options={[
                    { key: 'yes', value: 'yes', text: 'Yes' },
                    { key: 'no', value: 'no', text: 'No' },
                  ]}
                />
              </Form.Group>
            </div>

            <Button color="blue" loading={isLoading}>
              Save
            </Button>
          </Form>
        </Grid.Column>

        <Grid.Column>
          <Header as="h4">Current Balance</Header>

          <p>
            <Label
              as="span"
              color={currentBalance <= 0 ? 'red' : 'green'}
              size="large"
              style={{ marginRight: '0.25em' }}
            >
              {currentBalance.toFixed(2)}
            </Label>

            <AdminAccountBalanceAdjustment account={account} billingConfig="leadscorePlus" />
          </p>

          <Link to={`/admin/accounts/${account.id}/billing/leadscore-plus/history`}>
            <Button as="span" color="blue" compact>
              <Icon name="list ul" /> History
            </Button>
          </Link>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AdminAccountBillingLeadscorePlus;
