import { format } from 'date-fns';
import { SemanticCOLORS } from 'semantic-ui-react';

import {
  Account,
  AccountBilling,
  AccountPermission,
  AccountUserAccessRole,
  BillingAutopay,
  ConvosoFileStatus,
  JobStatus,
  LeadscoreConfig,
  ModelStage,
  ModelVersion,
  PaymentMethodType,
  ReportingTimeRange,
  User,
  WebhookConfig,
} from './types';

export const cleanName = (s: string): string => {
  // special chars
  s = s.replace(/[^\-|/a-zA-Z0-9_ ]+/g, '');

  // non word chars
  s = s.replace(/[-|/]+/g, '_');

  // uppercase first letter of each word
  s = s
    .split(' ')
    .map(p => {
      if (p.length === 0) return p;
      return p[0].toUpperCase() + p.slice(1);
    })
    .join(' ');

  // bad chars
  s = s.replace(/[^a-zA-Z0-9_]+/g, '');

  // underscores
  s = s.replace(/[_]+/g, '_');

  return s;
};

export const cleanPhone = (phone: any): string => {
  if (typeof phone === 'number') phone = String(phone);
  if (typeof phone !== 'string') return '';

  if (phone.indexOf('@') > -1 || phone.indexOf('sip:') > -1) {
    return cleanSipPhone(phone);
  }

  let p = phone;

  if (p.indexOf('.') > -1 && !Number.isNaN(Number(p))) {
    p = p.split('.')[0];
  }

  p = p.replace(/\D+/g, '').replace(/^1/, '');

  if (p.length < 10) {
    p = '';
  }

  p = p.substring(p.length - 10, p.length);

  return p;
};

export const cleanSipPhone = (phone: any): string => {
  if (typeof phone !== 'string') return '';

  let p = phone;

  const parts = p.split('@');
  if (parts.length > 2) return phone;

  // don't cleanPhone() since different sip servers have different rules
  p = parts[0].replace('sip:', '');
  const addr = parts[1] || '';

  return `sip:${p}@${addr}`;
};

const BAD_ZIPS = ['00000', '11111'];

export const cleanZip = (zip: any): string => {
  if (typeof zip === 'number') zip = String(zip);
  if (typeof zip !== 'string') return '';

  let z = zip;

  // canadian zip
  if (/[a-z]{1}[0-9]{1}[a-z]{1} [0-9]{1}[a-z]{1}[0-9]{1}/.test(z)) {
    return '';
  }

  z = z.replace(/[a-zA-Z]+/g, '').trim();

  if (z.indexOf('.') > -1 && !Number.isNaN(Number(z))) {
    z = z.split('.')[0];
  }
  if (z.indexOf('-') > -1) {
    z = z.split('-')[0];
  }
  if (z.indexOf(' ') > -1) {
    z = z.split(' ')[0];
  }

  z = z.replace(/\D+/g, '');

  if (z.length > 0 && z.length < 5) {
    z = '00000' + z;
    z = z.substring(z.length - 5, z.length);
  }

  if (BAD_ZIPS.indexOf(z) > -1) {
    return '';
  }

  return z;
};

export const hasAccountPermission = (user: User | undefined, permission: AccountPermission): boolean => {
  if (typeof user === 'undefined') return false;
  if (typeof user.active_account.permissions === 'undefined') return false;
  if (user.active_account.permissions.length === 0) return false;

  return user.active_account.permissions.indexOf(permission) > -1;
};

export const getConvosoFileStatusColor = (status: ConvosoFileStatus): undefined | SemanticCOLORS => {
  switch (status) {
    case ConvosoFileStatus.Locked:
      return 'orange';
    case ConvosoFileStatus.Released:
    case ConvosoFileStatus.Uploaded:
      return 'green';
    case ConvosoFileStatus.Created:
    default:
      return undefined;
  }
};

export const getJobStatusColor = (status: JobStatus): undefined | SemanticCOLORS => {
  switch (status) {
    case JobStatus.Completed:
    case JobStatus.Ready:
      return 'green';
    case JobStatus.Error:
      return 'red';
    default:
      return undefined;
  }
};

export const getModelVersionColor = (v: ModelVersion): SemanticCOLORS | undefined => {
  let color: SemanticCOLORS | undefined;

  switch (v.stage) {
    case ModelStage.Production:
      color = 'green';
      break;

    case ModelStage.Staging:
      color = 'orange';
      break;

    case ModelStage.Development:
      color = 'red';
      break;
  }

  return color;
};

export const accountLeadscorePlusBillingIsCurrent = (a: Account): boolean => {
  if (!a.billing.enabled || !a.billing.leadscorePlus.enabled) return true;

  return (a.currentBalanceLeadscorePlus || 0) > 0;
};

export const accountConnectProBillingIsCurrent = (a: Account): boolean => {
  if (!a.billing.enabled || !a.billing.connectPro.enabled) return true;

  if (a.billing.connectPro.type === 'monthly') {
    return a.billing.connectPro.subscription.isActive || false;
  }

  return (a.currentBalanceConnectPro || 0) > 0;
};

export const formatNumber = (n: any): number => {
  n = Number(n);
  if (Number.isNaN(n)) {
    return 0;
  }
  return n;
};

export const formatBillingAutopay = (a: BillingAutopay): BillingAutopay => {
  a.threshold = formatNumber(a.threshold);
  a.amount = formatNumber(a.amount);

  return a;
};

export const formatBilling = (b: AccountBilling): AccountBilling => {
  b.connectPro.autopay = formatBillingAutopay(b.connectPro.autopay);

  b.connectPro.deposit.amount = formatNumber(b.connectPro.deposit.amount);
  if (typeof b.connectPro.deposit.paid === 'string') {
    b.connectPro.deposit.paid = b.connectPro.deposit.paid === 'yes';
  }

  b.connectPro.rate = formatNumber(b.connectPro.rate);
  b.connectPro.subscription.day = formatNumber(b.connectPro.subscription.day);

  b.connectPro.minimumSeconds = formatNumber(b.connectPro.minimumSeconds);
  b.connectPro.intervalSeconds = formatNumber(b.connectPro.intervalSeconds);

  b.leadscorePlus.autopay = formatBillingAutopay(b.leadscorePlus.autopay);

  b.leadscorePlus.deposit.amount = formatNumber(b.leadscorePlus.deposit.amount);
  if (typeof b.leadscorePlus.deposit.paid === 'string') {
    b.leadscorePlus.deposit.paid = b.leadscorePlus.deposit.paid === 'yes';
  }

  b.leadscorePlus.rates.census = formatNumber(b.leadscorePlus.rates.census);
  // b.leadscorePlus.rates.cima = formatNumber(b.leadscorePlus.rates.cima);
  // b.leadscorePlus.rates.phoneLookup = formatNumber(b.leadscorePlus.rates.phoneLookup);
  b.leadscorePlus.rates.prediction = formatNumber(b.leadscorePlus.rates.prediction);

  return b;
};

export const formatLeadscoreConfig = (c: LeadscoreConfig): LeadscoreConfig => {
  c.enrichment.censusRate = formatNumber(c.enrichment.censusRate);
  // c.enrichment.cimaRate = formatNumber(c.enrichment.cimaRate);
  // c.enrichment.phoneLookupRate = formatNumber(c.enrichment.phoneLookupRate);

  c.scoring.akkioModelId = formatNumber(c.scoring.akkioModelId);
  c.scoring.threshold.from = formatNumber(c.scoring.threshold.from);
  c.scoring.threshold.to = formatNumber(c.scoring.threshold.to);
  c.scoring.rate = formatNumber(c.scoring.rate);

  return c;
};

export const formatWebhookConfig = (c: WebhookConfig): WebhookConfig => {
  c.request.timeout = formatNumber(c.request.timeout);
  c.data_enrichment_rate = formatNumber(c.data_enrichment_rate);

  return c;
};

export const hasAccountAccessRoles = (user: User, roles: AccountUserAccessRole[]): boolean => {
  if (user.role === 'admin') return true;
  const userAccess = user.active_account?.user_access.find(u => u.user_id === user.id);
  if (!userAccess) return false;
  return userAccess.roles.some(r => roles.includes(r));
};

export const reportingTimeRangeToDateStrings = (value: ReportingTimeRange): [string, string] => {
  const ONE_DAY_MS = 86400000;

  let startDate = '';
  let endDate = '';
  switch (value) {
    case 'today':
      startDate = endDate = format(new Date(), 'yyyy-MM-dd');
      break;

    case 'yesterday':
      startDate = endDate = format(new Date(Date.now() - ONE_DAY_MS), 'yyyy-MM-dd');
      break;

    case 'this week':
      // startDate = sunday, endDate = today
      startDate = format(new Date(Date.now() - ONE_DAY_MS * new Date().getDay()), 'yyyy-MM-dd');
      endDate = format(new Date(), 'yyyy-MM-dd');
      break;

    case 'last week':
      startDate = format(new Date(Date.now() - ONE_DAY_MS * (new Date().getDay() + 7)), 'yyyy-MM-dd');
      endDate = format(new Date(Date.now() - ONE_DAY_MS * new Date().getDay()), 'yyyy-MM-dd');
      break;

    case 'this month':
      startDate = format(new Date().setDate(1), 'yyyy-MM-dd');
      endDate = format(new Date(), 'yyyy-MM-dd');
      break;

    case 'last month':
      startDate = format(new Date().setMonth(new Date().getMonth() - 1, 1), 'yyyy-MM-dd');
      endDate = format(new Date(new Date().setMonth(new Date().getMonth(), 0)), 'yyyy-MM-dd');
      break;

    case 'previous month':
      startDate = format(new Date().setMonth(new Date().getMonth() - 2, 1), 'yyyy-MM-dd');
      endDate = format(new Date(new Date().setMonth(new Date().getMonth() - 1, 0)), 'yyyy-MM-dd');
      break;

    case 'last 90 days':
      startDate = format(new Date(Date.now() - ONE_DAY_MS * 90), 'yyyy-MM-dd');
      endDate = format(new Date(), 'yyyy-MM-dd');
      break;

    case 'this year':
      startDate = format(new Date().setMonth(0, 1), 'yyyy-MM-dd');
      endDate = format(new Date(), 'yyyy-MM-dd');
      break;

    case 'last year':
      const lastYear = new Date().getFullYear() - 1;

      startDate = format(new Date().setFullYear(lastYear, 0, 1), 'yyyy-MM-dd');
      endDate = format(new Date().setFullYear(lastYear, 11, 31), 'yyyy-MM-dd');
      break;
  }

  return [startDate, endDate];
};

export const getObjectPaths = (obj: Record<string, any>, prefix = ''): string[] => {
  let paths: string[] = [];
  for (const [key, value] of Object.entries(obj)) {
    const fullPath = prefix ? `${prefix}.${key}` : key;
    if (
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value) &&
      !Object.keys(value).includes('__typedef')
    ) {
      paths = paths.concat(getObjectPaths(value, fullPath));
    } else {
      paths.push(fullPath);
    }
  }
  return paths;
};

export const validEmail = (email: string) => {
  // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
  const re =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+`/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+`/=?^_{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

  return re.test(email);
};

export const calculatePaymentAmount = (amount: number, method: PaymentMethodType) => {
  switch (method) {
    case PaymentMethodType.BANK_ACCOUNT:
      const amountWithFee = amount / (1 - 0.008);
      if (amountWithFee * 0.008 > 5) {
        return amount + 5;
      }
      return amountWithFee;
    case PaymentMethodType.CARD:
      return (amount + 0.32) / (1 - 0.029);
    default:
      return amount;
  }
};

export const msToHourMinSec = (milliseconds: number) => {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
  const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);
  return [hours, minutes, seconds];
};

export const getAudioDuration = (url: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    const audio = new Audio(url);
    audio.addEventListener('loadedmetadata', function () {
      resolve(audio.duration);
    });
    audio.addEventListener('error', function () {
      reject(new Error('Error loading audio file'));
    });
  });
};
