import { useQuery } from 'react-query';

import { TTSv3Voice } from 'src/types';
import { ApiResponse, http } from './http-common';

export type QualifaiTTSVoicesResponse = ApiResponse & {
  voices: TTSv3Voice[];
};

export const useListQualifaiTTSVoicesQuery = () => {
  return useQuery(['qualifai', 'tts', 'voices'], async () => {
    const res = await http.get<QualifaiTTSVoicesResponse>('/api/qualifai/tts/voices');
    return res.data.voices;
  });
};
