import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  AccountBilling,
  AccountBrooks,
  AccountCommio,
  AccountHubspot,
  AccountMetadata,
  AccountMetadataRebuildJob,
  AccountPermission,
  AccountReporting,
  AccountVoice,
} from 'src/types';
import {
  AccountResponse,
  AccountsResponse,
  BillingConnectProTransactionResponse,
  BillingConnectProTransactionsResponse,
  BillingLeadscorePlusTransactionsResponse,
  ReportingStatsResponse,
} from '../accounts';
import { ApiResponse, http } from '../http-common';

export type AddAccountInput = {
  name: string;
  datasetName: string;
  ownerEmail: string;
  inviteOwner: boolean;
  // owners: string[];
};

export const useAddAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: AddAccountInput) => {
      const res = await http.post<AccountResponse>('/api/admin/accounts', input);
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        // queryClient.removeQueries(['admin/accounts', account.id]); // drop the cached result.
        queryClient.invalidateQueries(['admin/accounts']);
      },
    }
  );
};

export type ListAccountsFilters = {
  enabled?: boolean;
  search?: string;
};

export type ListAccountsParams = ListAccountsFilters & {
  limit: number;
  offset: number;
};

export const useListAccountsQuery = (params: ListAccountsParams) => {
  return useQuery(['admin/accounts', params], async () => {
    const res = await http.get<AccountsResponse>('/api/admin/accounts', { params });
    return res.data.accounts;
  });
};

export const useGetAccountQuery = (id: number) => {
  return useQuery(['admin/accounts', id], async () => {
    const res = await http.get<AccountResponse>(`/api/admin/accounts/${id}`);
    return res.data.account;
  });
};

type ListReportingStatsParams = {
  startDate: string;
  endDate: string;
  enabled?: boolean;
  timezone?: string;
};

export const useListReportingStatsQuery = (params: ListReportingStatsParams) => {
  return useQuery(['admin/reporting/stats', params], async () => {
    const res = await http.get<ReportingStatsResponse>('/api/admin/reporting/stats', { params });
    if (res.data.data === null) {
      return [];
    }
    return res.data.data.map(stat => ({
      ...stat,
      connect_pct: stat.dials > 0 ? Number(((stat.connects / stat.dials) * 100).toFixed(2)) : 100,
      transfer_pct: stat.transfers > 0 ? Number(((stat.inbounds / stat.transfers) * 100).toFixed(2)) : 100,
      xfer_to_callcenter_pct:
        stat.humans > 0 ? Number(((stat.xfer_to_callcenter / stat.humans) * 100).toFixed(2)) : 100,
    }));
  });
};

type ReportingStatsOverTimeParams = {
  startDate: string;
  endDate: string;
  accountId: number;
  voiceConfigId?: number;
  timezone?: string;
  interval?: string;
};
type ReportingVoiceConfigs = {
  id: number;
  name: string;
};
type ReportingStatsOverTimeResponse = {
  accountId: number;
  accountName: string;
  data: ReportingStatsOverTime[];
  voiceConfigs: ReportingVoiceConfigs[];
  timezone: string;
};
export const ReportingStatOverTimeAttributes = [
  'time',
  'account_id',
  'dials',
  'connects',
  'connect_pct',
  'inbounds',
  'transfers',
  'transfer_pct',
  'humans',
  'xfer_to_callcenter',
  'xfer_to_callcenter_pct',
] as const;
type AttributeKeys = typeof ReportingStatOverTimeAttributes[number];
type ReportingStatsOverTime = {
  [K in AttributeKeys]: K extends 'time'
    ? string
    : K extends 'dials' | 'connects' | 'inbounds' | 'transfers' | 'humans' | 'xfer_to_callcenter'
    ? number
    : K extends 'connect_pct' | 'transfer_pct' | 'xfer_to_callcenter_pct'
    ? number | undefined
    : never;
};

export const useDetailReportingStatsQuery = (params: ReportingStatsOverTimeParams) => {
  return useQuery([`admin/reporting/stats${params.accountId}`, params], async () => {
    const res = await http.get<ReportingStatsOverTimeResponse>(`/api/admin/reporting/stats/${params.accountId}`, {
      params,
    });
    return {
      accountName: res.data.accountName,
      data: res.data.data.map(stat => ({
        ...stat,
        connect_pct: stat.dials > 0 ? Number(((stat.connects / stat.dials) * 100).toFixed(2)) : 100,
        transfer_pct: stat.transfers > 0 ? Number(((stat.inbounds / stat.transfers) * 100).toFixed(2)) : 100,
        xfer_to_callcenter_pct:
          stat.humans > 0 ? Number(((stat.xfer_to_callcenter / stat.humans) * 100).toFixed(2)) : 100,
      })),
      voiceConfigs: res.data.voiceConfigs,
      timezone: res.data.timezone,
    };
  });
};

export type UpdateAccountInput = {
  id: number;
  name?: string;
  ownerEmail?: string;
  permissions?: AccountPermission[];
  enabled?: boolean;
  billing?: AccountBilling;
  reporting?: AccountReporting;
  hubspot?: AccountHubspot;
  commio?: AccountCommio;
  voice?: AccountVoice;
  metadata?: AccountMetadata;
  brooks?: AccountBrooks;
};

export const useUpdateAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...input }: UpdateAccountInput) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${id}`, input);
      return res.data.account;
    },
    {
      onSuccess: (_, { id }) => {
        // queryClient.removeQueries(['admin/accounts', id]); // drop the cached result.
        queryClient.invalidateQueries(['admin/accounts']);
        queryClient.invalidateQueries(['auth/profile']);
      },
    }
  );
};

export type ModelToAccountInput = {
  accountId: number;
  modelIds: number[];
};

export const useAssignModelToAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, modelIds }: ModelToAccountInput) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${accountId}/models`, { model_ids: modelIds });
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
      },
    }
  );
};

export type AkkioModelToAccountInput = {
  accountId: number;
  modelIds: number[];
};

export const useAssignAkkioModelToAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, modelIds }: AkkioModelToAccountInput) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${accountId}/akkio-models`, { modelIds });
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
      },
    }
  );
};

export type SetAkkioModelRateInput = {
  accountId: number;
  modelId: number;
  rate?: number;
};

export const useSetAkkioModelRateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, modelId, rate }: SetModelCostInput) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${accountId}/akkio-models/${modelId}`, {
        rate,
      });
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
      },
    }
  );
};

export type DeleteAkkioModelAccountAccessInput = {
  accountId: number;
  modelId: number;
};

export const useDeleteAkkioModelAccountAccessMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, modelId }: DeleteAkkioModelAccountAccessInput) => {
      const res = await http.delete<AccountResponse>(`/api/admin/accounts/${accountId}/akkio-models/${modelId}`);
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
      },
    }
  );
};

export type AssignAccountUserRole = {
  accountId: number;
  userId: number;
  roles: string[];
};

export const useAssignAccountUserRoleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, userId, roles }: AssignAccountUserRole) => {
      const res = await http.put<AccountResponse>(`/api/admin/accounts/${accountId}/user-access`, {
        user_id: userId,
        roles,
      });
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
      },
    }
  );
};

export type AssignUserAccessInput = {
  accountId: number;
  userIds: number[];
  roles: string[];
};

export const useAssignUserAccessToAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, userIds, roles }: AssignUserAccessInput) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${accountId}/user-access`, {
        user_ids: userIds,
        roles,
      });
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
      },
    }
  );
};

export type ListBillingConnectProTransactionsParams = {
  limit: number;
  offset: number;
};

export const useListBillingConnectProTransactionsQuery = ({
  accountId,
  ...params
}: { accountId: number } & ListBillingConnectProTransactionsParams) => {
  return useQuery(
    ['admin/accounts', accountId, 'billing/connect-pro/transactions', params],
    async () => {
      const res = await http.get<BillingConnectProTransactionsResponse>(
        `/api/admin/accounts/${accountId}/billing/connect-pro/transactions`,
        { params }
      );
      return res.data.transactions;
    },
    {
      retry: false,
    }
  );
};

export const useListAccountBillingLeadscorePLusTransactionsQuery = ({
  accountId,
  ...params
}: { accountId: number } & ListBillingConnectProTransactionsParams) => {
  return useQuery(
    ['admin/accounts', accountId, 'billing/leadscore-plus/transactions', params],
    async () => {
      const res = await http.get<BillingLeadscorePlusTransactionsResponse>(
        `/api/admin/accounts/${accountId}/billing/leadscore-plus/transactions`,
        { params }
      );
      return res.data.transactions;
    },
    {
      retry: false,
    }
  );
};

type AccountCreateBillingLeadscorePlusTransactionInput = {
  accountId: number;
  amount: number;
  note: string;
};

export const useCreateBillingLeadscorePlusTransactionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, ...input }: AccountCreateBillingLeadscorePlusTransactionInput) => {
      const res = await http.post<AccountResponse>(
        `/api/admin/accounts/${accountId}/billing/leadscore-plus/transactions`,
        input
      );
      return res.data.account;
    },
    {
      onSuccess: (_, { accountId }) => {
        queryClient.invalidateQueries(['admin/accounts', accountId]);
      },
    }
  );
};

type AccountCreateBillingConnectProTransactionInput = {
  accountId: number;
  amount: number;
  note: string;
};

export const useCreateBillingConnectProTransactionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, ...input }: AccountCreateBillingConnectProTransactionInput) => {
      const res = await http.post<AccountResponse>(
        `/api/admin/accounts/${accountId}/billing/connect-pro/transactions`,
        input
      );
      return res.data.account;
    },
    {
      onSuccess: (_, { accountId }) => {
        queryClient.invalidateQueries(['admin/accounts', accountId]);
      },
    }
  );
};

type AccountEditBalanceTransactionInput = {
  accountId: number;
  transactionId: number;
  title: string;
  note: string;
};

export const useEditBillingConnectProTransactionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, transactionId, ...input }: AccountEditBalanceTransactionInput) => {
      const res = await http.post<BillingConnectProTransactionResponse>(
        `/api/admin/accounts/${accountId}/billing/connect-pro/transactions/${transactionId}`,
        input
      );
      return res.data.transaction;
    },
    {
      onSuccess: (_, { accountId }) => {
        if (!accountId) return;
        queryClient.invalidateQueries(['admin/accounts', accountId]);
      },
    }
  );
};

export const useRemoveModelFromAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, modelIds }: ModelToAccountInput) => {
      const res = await http.delete<AccountResponse>(`/api/admin/accounts/${accountId}/models/${modelIds[0]}`);
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
      },
    }
  );
};

export type RemoveUserAccessInput = {
  accountId: number;
  userId: number;
};

export const useRemoveUserAccessFromAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, userId }: RemoveUserAccessInput) => {
      const res = await http.delete(`/api/admin/accounts/${accountId}/user-access`, {
        params: {
          user_id: userId,
        },
      });

      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/accounts']);
      },
    }
  );
};

export type SetModelCostInput = {
  accountId: number;
  modelId: number;
  rate?: number;
};

export const useSetModelCostMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, modelId, rate }: SetModelCostInput) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${accountId}/models/${modelId}`, { rate });
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
      },
    }
  );
};

type GetAccountRecentJobsResponse = {
  job?: AccountMetadataRebuildJob;
};

export const useGetAccountRecentJobsQuery = (accountId: number) => {
  return useQuery(
    ['admin/accounts', accountId, 'jobs/recent'],
    async () => {
      const res = await http.get<GetAccountRecentJobsResponse>(`/api/admin/accounts/${accountId}/jobs/recent`);
      return res.data.job;
    },
    {
      retry: false,
    }
  );
};

export const useCreateAccountMetadataRebuildJobMutation = (accountId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const res = await http.post<ApiResponse>(`/api/admin/accounts/${accountId}/jobs/rebuild-metadata`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/accounts', accountId, 'jobs/recent']);
      },
    }
  );
};

export const useArchiveAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${id}/archive`, id);
      return res.data.account;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/accounts']);
        queryClient.invalidateQueries(['auth/profile']);
      },
    }
  );
};
