import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Label, Placeholder, PlaceholderParagraph, Table } from 'semantic-ui-react';

import { useListAllQualifaiConversationsQuery } from 'src/api/qualifai-conversations';
import { RenderProps } from 'src/components/PaginatedTable';
import { Note } from 'src/styles';
import theme from 'src/styles/theme';
import DeleteQualifaiConversation from './DeleteQualifaiConversation';
import ToggleQualifaiConversationEnabledButton from './ToggleQualifaiConversationEnabledButton';

const PlaceholderRow = () => (
  <Table.Row>
    <Table.Cell collapsing>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell textAlign="right">
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
  </Table.Row>
);

const ListQualifaiConversationsBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: conversations, isLoading } = useListAllQualifaiConversationsQuery({
    // enabled: true,
    limit,
    offset: limit * (currentPage - 1),
  });

  useEffect(() => {
    let count = 1;
    if (typeof conversations !== 'undefined') {
      count = Math.ceil(conversations.total / limit);
    }
    setPageCount(count);
  }, [conversations, limit, setPageCount]);

  if (isLoading) {
    return <PlaceholderRow />;
  }

  if (!conversations?.data.length) {
    return (
      <Table.Row>
        <Table.Cell colSpan={5}>
          <span style={{ color: theme.gray }}>No conversations found</span>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {conversations?.data.map((c, i) => (
        <Table.Row key={c.id}>
          <Table.Cell collapsing>
            <ToggleQualifaiConversationEnabledButton conversation={c} />
          </Table.Cell>
          <Table.Cell>
            <Link to={`/voice/conversations/${c.id}`}>
              <strong>{c.name}</strong>
            </Link>
            <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>{c.id}</span>
          </Table.Cell>
          <Table.Cell>
            {c.ttsVoiceId === '' ? (
              <Note style={{ opacity: 0.5 }}>No Voice Selected</Note>
            ) : c.ttsVoiceStatus === 'ready' ? (
              <Label color="green">
                <Icon name="microphone" />
                TTS Ready
              </Label>
            ) : c.ttsVoiceStatus === 'needs_update' ? (
              <Label color="orange">
                <Icon name="microphone slash" />
                TTS Needs Update
              </Label>
            ) : (
              <Label color="blue">
                <Icon name="spinner" loading />
                TTS Generating Audios
              </Label>
            )}
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            {/* <DuplicateVoiceConversation id={c.id} /> */}
            <Link to={{ pathname: `/voice/conversations/${c.id}` }}>
              <Button as="div" color="blue" icon title="Edit Conversation">
                <Icon name="pencil" />
              </Button>
            </Link>
            <DeleteQualifaiConversation id={c.id} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ListQualifaiConversationsBody;
