import { Icon, Label } from 'semantic-ui-react';

import { LeadscoreConfig, LeadscoreDefaultRates as Rates } from 'src/types';

type Props = {
  config: LeadscoreConfig;
};

const LeadscoreConfigCosts = ({ config: c }: Props) => {
  const { enrichment: e, scoring: s } = c;

  if (!c.enabled)
    return (
      <Label color="yellow">
        <Icon name="warning circle" /> Config Disabled
      </Label>
    );

  const enrichmentCost = e.censusEnabled ? e.censusRate || Rates.census : 0;
  // + (e.cimaEnabled ? e.cimaRate || Rates.cima : 0)
  // + (e.phoneLookupEnabled ? e.phoneLookupRate || Rates.phoneLookup : 0);

  const predictionCost = s.akkioModelId ? s.rate || Rates.prediction : 0;

  const totalCost = enrichmentCost + predictionCost;

  return (
    <>
      <Label>
        {enrichmentCost.toFixed(4)}
        <Label.Detail>Enrichment</Label.Detail>
      </Label>{' '}
      +{' '}
      <Label>
        {predictionCost.toFixed(4)}
        <Label.Detail>Prediction</Label.Detail>
      </Label>{' '}
      ={' '}
      <Label color="blue">
        {totalCost.toFixed(4)}
        <Label.Detail>Total Cost per Lead</Label.Detail>
      </Label>
    </>
  );
};

export default LeadscoreConfigCosts;
