import { formatDistanceToNow } from 'date-fns';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Label, Placeholder, Table } from 'semantic-ui-react';

import { useListAccountBillingLeadscorePLusTransactionsQuery } from 'src/api/admin/accounts';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import EditBalanceTransaction from './EditBalanceTransaction';

const PlaceholderRow = () => (
  <Table.Row>
    <Table.Cell>
      <Placeholder>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <Placeholder.Line />
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <Placeholder.Line />
      </Placeholder>
    </Table.Cell>
    <Table.Cell collapsing>
      <Placeholder>
        <Placeholder.Line />
      </Placeholder>
    </Table.Cell>
  </Table.Row>
);

const AdminBillingLeadscorePlusTransactionsListBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const params = useParams<{ id: string }>();
  const { data: transactions, isLoading } = useListAccountBillingLeadscorePLusTransactionsQuery({
    accountId: Number(params.id),
    limit,
    offset: limit * (currentPage - 1),
  });

  useEffect(() => {
    let count = 1;
    if (typeof transactions !== 'undefined') {
      count = Math.ceil(transactions.total / limit);
    }
    setPageCount(count);
  }, [transactions, limit, setPageCount]);

  if (isLoading) {
    return <PlaceholderRow />;
  }

  if (!transactions?.data.length) {
    return (
      <Table.Row>
        <Table.Cell colSpan={4}>
          <span style={{ color: theme.gray }}>No history found</span>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {transactions?.data.map(t => {
        const p = t.payload;

        const txDetails = [
          p.censusSpent ? `census=${p.censusSpent}` : '',
          // p.cimaSpent ? `cima=${p.cimaSpent}` : '',
          // p.phoneLookupSpent ? `phoneLookup=${p.phoneLookupSpent}` : '',
          p.leadscoreSpent ? `leadscore=${p.leadscoreSpent}` : '',
        ]
          .filter(Boolean)
          .join(' ');

        return (
          <Table.Row key={t.id}>
            <Table.Cell>
              <Row style={{ flexDirection: 'column' }}>
                <strong>{t.title}</strong>
                <span style={{ color: theme.gray }}>{t.note || txDetails}</span>
              </Row>
            </Table.Cell>
            <Table.Cell>
              <Label title={t.createdAt}>
                {formatDistanceToNow(new Date(t.createdAt), { addSuffix: true, includeSeconds: true })}
              </Label>
            </Table.Cell>
            <Table.Cell>
              <Label color={t.amount > 0 ? 'green' : 'red'}>{t.amount.toFixed(4)}</Label>
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              <EditBalanceTransaction transaction={t} />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AdminBillingLeadscorePlusTransactionsListBody;
