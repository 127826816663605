import { get } from 'lodash';
import { DropdownItemProps } from 'semantic-ui-react';

import { getObjectPaths } from 'src/utils';
// import BrooksCimaCertProperties from './brooks-cima-cert-types.json';
// import BrooksPhoneLookupProperties from './brooks-phone-lookup-v2-types.json';
import DsCensusProperties from './ds-census-types.json';
import DsMetadataProperties from './ds-metadata-types.json';

export const DsCensusPropertyOptions = getObjectPaths(DsCensusProperties).map(k => {
  const key = `census.${k}`;
  const opt: DropdownItemProps = { key, value: key, text: key };

  const prop = get(DsCensusProperties, k);
  if (prop && typeof prop !== 'string' && prop.friendlyName) {
    opt.description = prop.friendlyName;
  }

  return opt;
});

export const DsMetadataPropertyOptions = getObjectPaths(DsMetadataProperties).map(k => {
  const key = `metadata.${k}`;
  const opt: DropdownItemProps = { key, value: key, text: key };

  const prop = get(DsMetadataProperties, k);
  if (prop && typeof prop !== 'string' && prop.friendlyName) {
    opt.description = prop.friendlyName;
  }

  return opt;
});

// export const BrooksCimaCertPropertyOptions = getObjectPaths(BrooksCimaCertProperties).map(k => {
//   const opt: DropdownItemProps = { key: k, value: k, text: k };

//   const prop = get(BrooksCimaCertProperties, k);
//   if (prop && typeof prop !== 'string' && prop.friendlyName) {
//     opt.description = prop.friendlyName;
//   }

//   return opt;
// });

// export const BrooksPhoneLookupPropertyOptions = getObjectPaths(BrooksPhoneLookupProperties).map(k => {
//   const opt: DropdownItemProps = { key: k, value: k, text: k };

//   const prop = get(BrooksPhoneLookupProperties, k);
//   if (prop && typeof prop !== 'string' && prop.friendlyName) {
//     opt.description = prop.friendlyName;
//   }

//   return opt;
// });
