import { Helmet } from 'react-helmet-async';

import JobHistory from 'src/components/JobHistory';
import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header, Row } from 'src/styles';
import { JobType } from 'src/types';
import AddBlacklist from './AddBlacklist';
import BlacklistJobDetailModal from './BlacklistJobDetailModal';
import ListBlacklistsBody from './ListBlacklistsBody';

const BlacklistsList = () => {
  return (
    <Container>
      <Helmet>
        <title>Blacklists | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>Blacklists</Header>
        <AddBlacklist />
      </Row>

      <PaginatedTable headers={['ID', 'Name', 'URL', '', '']} renderBody={ListBlacklistsBody} />

      <JobHistory jobType={JobType.BlacklistIngest} qsParam="jp" details={BlacklistJobDetailModal} />
    </Container>
  );
};

export default BlacklistsList;
